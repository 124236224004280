<template>
	<div>
		<jy-dialog
			type="table"
			:title="title"
			:visible.sync="dialogVisible"
			custom-class="dialog_table"
			:width="global.dialogTabelWidth"
		>
			<div>
				<jy-table max-height="595" :data="lists">
					<jy-table-column type="index" width="60" label="序号" align="center"> </jy-table-column>
					<jy-table-column prop="doorNo" label="门编号" min-width="100"> </jy-table-column>
					<jy-table-column prop="onCount" label="上客数" min-width="100"> </jy-table-column>
					<jy-table-column prop="offCount" label="下客数" min-width="100"> </jy-table-column>
				</jy-table>
			</div>
			<div slot="footer" class="dialog-footer"></div>
		</jy-dialog>
	</div>
</template>
<script>
export default {
	data() {
		return {
			title: "上下客数明细",

			lists: [],
			dialogVisible: false,
			id: ""
		};
	},
	watch: {
		// dialogVisible(newview) {
		//     if (!newview) {
		//     }
		// }
	},
	methods: {
		// 打开对话框
		init(row) {
			this.dialogVisible = true;
			this.id = row.id;
			this.getlist();
		},
		getlist() {
			let url = "/stat/ArrivalLeaveStop/queryUpDownNum";
			let option = {
				relId: this.id
			};
			this.$http.post(url, option).then(({ detail }) => {
				this.lists = detail;
			});
		}
	}
};
</script>
<style lang="scss" scoped></style>
