<template>
	<div id="arriveLeaveDetail">
		<jy-query ref="form" :model="form">
			<!-- <jy-query-item label="机构:" prop="orgName" label-width="70px">
                <el-input placeholder="请选择" v-model="form.orgName" @focus="xlselectInstitutions"></el-input>
            </jy-query-item> -->
			<jy-query-item label="线路:" prop="routeId" label-width="70px">
				<!-- <el-input v-model="form.routeName" @focus="selectInstitutions"></el-input> -->
				<el-select v-model="form.routeId" placeholder="请选择线路">
					<el-option v-for="item in routeOptions" :key="item.routeId" :label="item.name" :value="item.routeId"> </el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item prop="siteName" label="站点:">
				<el-input placeholder="请输入" v-model="form.siteName"></el-input>
			</jy-query-item>
			<jy-query-item prop="vehicleNo" label="车牌号:">
				<el-input placeholder="请输入" v-model="form.vehicleNo"></el-input>
			</jy-query-item>
			<jy-query-item prop="type" label="到离站类型:">
				<el-select v-model="form.type" placeholder="请选择">
					<el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item prop="bizType" label="方向:">
				<el-select v-model="form.bizType" placeholder="请选择">
					<el-option v-for="item in bizOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item label="查询日期:" prop="time" :span="1.5">
				<el-date-picker
					v-model="form.time"
					type="datetimerange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					value-format="yyyy-MM-dd HH:mm:ss"
					:picker-options="pickerOptions"
					:unlink-panels="true"
				></el-date-picker>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck" v-if="btnexist('list') || true">查询</el-button>
				<el-button type="primary" plain @click="resetForm('form')" v-if="btnexist('reset') || true">重置</el-button>
				<!-- <el-row>
				</el-row> -->
			</template>
			<template slot="operate">
				<el-button type="primary" @click="exportList" v-if="btnexist('export')">导出</el-button>
			</template>
		</jy-query>
		<jy-table max-height="595" :data="dataList" v-loading="loading">
			<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
			<jy-table-column prop="routeNo" label="线路编号" min-width="120"></jy-table-column>
			<jy-table-column prop="routeName" label="线路名称" min-width="120"></jy-table-column>
			<jy-table-column prop="orgName" label="所属机构" min-width="120"></jy-table-column>
			<jy-table-column prop="vehicleNo" label="车牌号" min-width="120"></jy-table-column>
			<jy-table-column prop="deviceNo" label="设备ID" min-width="120"></jy-table-column>
			<jy-table-column prop="stopNo" label="站点编号" min-width="120"></jy-table-column>
			<jy-table-column prop="stopName" label="站点名称" min-width="120"></jy-table-column>
			<jy-table-column prop="stopIndex" label="车站序号" min-width="90"></jy-table-column>
			<jy-table-column prop="typeName" label="到离站类型" min-width="100"></jy-table-column>
			<jy-table-column prop="bizTypeName" label="方向" min-width="90"></jy-table-column>
			<jy-table-column prop="bdLat" label="纬度" min-width="160"></jy-table-column>
			<jy-table-column prop="bdLon" label="经度" min-width="160"></jy-table-column>
			<jy-table-column prop="altitude" label="高程(米)" min-width="100"></jy-table-column>
			<jy-table-column prop="speed" label="速度km/h" min-width="100"></jy-table-column>
			<jy-table-column prop="addr" label="地理位置" min-width="200"></jy-table-column>
			<jy-table-column prop="time" label="时间" min-width="160"></jy-table-column>
			<!-- <jy-table-column prop="passengerCount" label="当前乘客数" min-width="100"></jy-table-column> -->
			<jy-table-column prop="doorCount" label="车门数"></jy-table-column>
			<!-- <jy-table-column label="上下客数">
                <template slot-scope="scope">
                    <span :class="scope.row.upDownNum !='-'?'click_box':''" @click="toDetail(scope.row)">
                        {{scope.row.upDownNum}}
                    </span>
                </template>
            </jy-table-column> -->
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		></jy-pagination>
		<xlinstitutions-tree
			ref="xlinstitutions"
			@addInstitutions="xladdInstitutions"
			url="/system/org/getUserOrgTree"
			title="选择机构"
		></xlinstitutions-tree>
		<institutions-tree
			ref="institutions"
			@addInstitutions="addInstitutionsLine"
			url="/waybillOperate/getTree"
			title="选择路线"
			:defaultProps="props"
		></institutions-tree>
		<detail ref="detail"></detail>
	</div>
</template>
<script>
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
import xlinstitutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
import detail from "@/components/pages/admin/detailData/arriveLeaveDetail/detail";
import { btnMixins } from "@/common/js/button.mixin";
const props = {
	children: "children",
	label: "text",
	value: "id"
};
export default {
	data() {
		return {
			loading: false,
			pickerOptions: {
				// disabledDate(time) {
				//     return time.getTime() > Date.now() - 8.64e7
				// },
			},
			form: {
				orgName: "",
				routeName: "",
				orgId: "",
				routeId: "",
				siteName: "",
				vehicleNo: "",
				type: "",
				bizType: "",
				time: [new Date().Format("yyyy-MM-dd") + " 00:00:00", new Date().Format("yyyy-MM-dd") + " 23:59:59"]
			},
			props,
			pageSize: 10,
			total: 0,
			pageIndex: 1,
			// 列表列数据
			dataList: [],

			typeOptions: [
				{
					value: "",
					label: "全部"
				},
				{
					value: "1",
					label: "到站"
				},
				{
					value: "2",
					label: "离站"
				}
			],
			bizOptions: [
				{
					value: "",
					label: "全部"
				},
				{
					value: "1",
					label: "上行"
				},
				{
					value: "2",
					label: "下行"
				},
				{
					value: "3",
					label: "环形"
				}
			],

			routeOptions: [],

			btnRoute: "/admin/detailData/arriveLeaveDetail"
		};
	},
	mixins: [btnMixins],
	components: {
		institutionsTree,
		xlinstitutionsTree,
		detail
	},
	created() {
		this.getList();
	},
	activated() {
		this.getRouteList();
	},
	methods: {
		getRouteList() {
			let url = "/baseinforoute/queryCurrentAuthorityRoute";
			this.$http.post(url).then(({ detail }) => {
				this.routeOptions = detail;
			});
		},
		getList() {
			let params = {
				...this.form,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			if (params.time != null && params.time.length) {
				params.startDate = params.time[0];
				params.endDate = params.time[1];

				let distan = 1000 * 60 * 60 * 24 * 3; //三天

				if (new Date(params.endDate).getTime() - new Date(params.startDate).getTime() >= distan) {
					this.$message({
						type: "warning",
						message: "时间最多三天"
					});
					return;
				}
			} else {
				params.startDate = "";
				params.endDate = "";
			}
			let url = "/stat/ArrivalLeaveStop/queryPage";
			// console.log(params)
			if (this.loading) {
				return;
			}
			let option = {
				err: () => {
					this.loading = false;
				}
			};
			this.loading = true;
			this.$http.post(url, params, option).then(res => {
				this.loading = false;
				this.dataList = res.detail.list;
				this.total = res.detail.total;
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.form.orgId = "";
			this.oncheck();
		},
		// 选择线路树
		xlselectInstitutions() {
			this.$refs.xlinstitutions.init();
		},
		selectInstitutions() {
			this.$refs.institutions.init();
		},
		// 上级线路机构查询
		xladdInstitutions(data) {
			// console.log(data)
			this.form.orgId = data.orgId;
			this.form.orgName = data.name;
		},
		addInstitutionsLine(data) {
			this.form.routeId = data.id;
			this.form.routeName = data.text;
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		},

		toDetail(row) {
			if (row.upDownNum != "-") {
				this.$refs.detail.init(row);
			}
		},
		//导出
		exportList() {
			let url = "/stat/ArrivalLeaveStop/export";
			let option = {
				...this.form
			};
			if (option.time != null && option.time.length) {
				option.startDate = option.time[0];
				option.endDate = option.time[1];
			} else {
				option.startDate = "";
				option.endDate = "";
			}
			this.$http.post(url, option, { type: "format", isExcel: true }).then(res => {
				this.$util.funDownload(res.data, "到离站明细");
			});
		}
	}
};
</script>
<style lang="scss" scoped="arriveLeaveDetail">
#arriveLeaveDetail {
	.click_box {
		color: #1989fa;
		cursor: pointer;
	}
}
</style>
